import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LoadingProvider {
  public loading: boolean = false;
  public loadChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.loading
  );

  constructor(
    public loadingCtrl: LoadingController,
    public translate: TranslateService
  ) {}

  start() {
    this.loading = true;
    this.loadChange.next(this.loading);
  }

  stop() {
    this.loading = false;
    this.loadChange.next(this.loading);
  }

  /**
   * Displays a spinner with a message.
   *
   * @param messageKey Internationalization Key
   */
  public async createAndShowSpinner(messageKey: string) {
    let translatedMessage = this.translate.instant(messageKey);
    let loader = await this.loadingCtrl.create({ message: translatedMessage });
    await loader.present();
    return loader;
  }
}
