import { BehaviorSubject } from "rxjs";
import { storageKeys } from "./storage-keys";
import { Injectable } from "@angular/core";
import { LoadingProvider } from "./loading/loading";
import { Article, FavoritesList, SafetyDataSheet } from "../types/types";
import { Storage } from "@ionic/storage";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "./dialog.service";
import { SafetyDataAPI } from "./safety-data-api.service";
import { FavoriteStoreService } from "./favorite-storage.service";
import { Events } from "./events/events";
import { validateAlphaNumericString } from "../validator";
import { AddListAlertPopup } from "../components/AddListAlertPopup/AddListAlertPopup";
import { InfocategoryEmptyListPopup } from "../components/InfocategoryEmptyListPopup/InfocategoryEmptyListPopup";
import { AddListModelPopup } from "../components/AddListModelPopup/AddListModelPopup";

@Injectable({
  providedIn: "root",
})
export class FavouritesService {
  private favourites$: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  favourites = this.favourites$.asObservable();

  public favoritesLists$: BehaviorSubject<FavoritesList[]> =
    new BehaviorSubject([]);
  favoritesLists = this.favoritesLists$.asObservable();

  public archivedLists$: BehaviorSubject<FavoritesList[]> = new BehaviorSubject(
    []
  );
  archivedLists = this.archivedLists$.asObservable();

  sds: SafetyDataSheet;
  expandListState: FavoritesList[];
  addListPopover: any;

  constructor(
    private storage: Storage,
    private alertCtrl: AlertController,
    private events: Events,
    private loading: LoadingProvider,
    private translate: TranslateService,
    private safetyAPIService: SafetyDataAPI,
    private dialogService: DialogService,
    private favoriteStore: FavoriteStoreService,
    public modalCtrl: ModalController
  ) {
    this.events.subscribe("favorites:load", () => {
      this.loadFavourites();
    });

    this.events.subscribe("favorites:unload", () => {
      this.unloadAllFavourites();
    });

    this.events.subscribe("archives:load", () => {
      this.loadArchivedLists();
    });

    this.favoritesLists.subscribe((expanded) => {
      this.expandListState = [...expanded];
    });
  }
  // load all favorites lists
  async loadFavourites() {
    let lists = await this.favoriteStore.getAllFavoritesLists();
    this.expandListState.forEach((outerList) => {
      lists = lists.map((favList) => {
        if (favList.name == outerList.name) {
          return { ...favList, expandList: outerList.expandList };
        }
        return favList;
      });
    });
    this.favoritesLists$.next(lists);
    this.updateFavoritesObserver(lists);
  }

  // load all archived lists
  async loadArchivedLists() {
    let lists = await this.favoriteStore.getAllArchivedLists();
    this.archivedLists$.next(lists);
  }

  // load all favorites lists
  public expandFavouritLists(lists: FavoritesList[]) {
    this.favoritesLists$.next(lists);
    this.favoriteStore.maitainExpandStateFavList(lists);
  }

  maitainExpandStateFavList(lists: FavoritesList[]) {
    this.favoriteStore.maitainExpandStateFavList(lists);
  }

  //load all archives list
  public expandArchivesLists(lists: FavoritesList[]) {
    this.archivedLists$.next(lists);
  }

  async createMultiplesFavoritesList(lists: FavoritesList[]) {
    try {
      await this.safetyAPIService.createMultiplesFavoritesList(lists);
      await this.favoriteStore.downloadFavourite(lists);
      // this.loadFavourites();
    } catch {
      this.dialogService.notify("UNEXPECTED_ERROR");
    }
  }

  // add a favorite in a list
  async addFavourite(favorite: Article, favoritesListName: string) {
    console.log("addFavourite", this.favoritesLists$);
    try {
      let response = await this.favoriteStore.addFavoriteToList(
        favorite,
        favoritesListName
      );
      await this.loadFavourites();
      return response;
    } catch (error) {
      console.log("AddFavourite error ", error);
    }
  }

  private async unloadAllFavourites() {
    this.favoritesLists.forEach((favList) => {
      favList.forEach((favItem) => {
        favItem.articles.forEach(async (item) => {
          await this.storage.remove(item.materialNumber);
        });
      });
    });
  }

  // remove favorite all lists
  async fullRemoveFavorite(favorite: Article) {
    try {
      await this.favoriteStore.removeFavoriteFromAllList(favorite);
      await this.loadFavourites();
    } catch (error) {
      console.log("FullRemoveFavourite error ", error);
    }
  }

  // remove favorite of a list
  async removeFavoriteFrom(favorite: Article, favoritesListName: string) {
    try {
      await this.favoriteStore.removeFavoriteToList(
        favorite,
        favoritesListName
      );
      await this.loadFavourites();
    } catch (error) {
      console.log("RemoveFavouriteFrom ", error);
    }
  }

  // remove favorite of a list
  async removeArchivedFrom(favorite: Article, favoritesListName: string) {
    try {
      await this.favoriteStore.removeArchivedToList(
        favorite,
        favoritesListName
      );
      await this.loadArchivedLists();
    } catch (error) {
      console.log("RemoveArchivedFrom ", error);
    }
  }

  // save all favorites lists in local storage
  async saveFavoritesLists(favoritesLists: FavoritesList[]) {
    try {
      await this.storage.set(storageKeys.favoritesLists, favoritesLists);
      await this.loadFavourites();
    } catch (error) {
      console.log("SaveFavouritesLists ", error);
    }
  }

  // remove all favorite lists
  async clearFavourites(showToast: boolean = true) {
    try {
      await this.favoriteStore.removeAllFavoritesLists();
      if (showToast) {
        this.dialogService.notify("LIST_DELETED_SUCCESS");
      }
      await this.loadFavourites();
    } catch (error) {
      console.log("CLearFavourties error", error);
    }
  }

  // remove all favorite lists
  async clearArchived(showToast: boolean = true) {
    try {
      await this.favoriteStore.removeAllArchivedLists();
      if (showToast) {
        this.dialogService.notify("LIST_DELETED_SUCCESS");
      }
      await this.loadArchivedLists();
    } catch (error) {
      console.log("CLearFavourties error", error);
    }
  }

  // create a favorite list by the API
  async addFavoritesList(
    name: string,
    articles: Article[] = [],
    uuid = ""
  ): Promise<{ success: boolean; error?: string }> {
    try {
      name = name.trim();
      const validation = validateAlphaNumericString(name);
      let listName = name;
      let lists = this.favoritesLists$.getValue();
      let list = lists.find(
        (favList) => favList.name.toLowerCase() === listName.toLowerCase()
      );
      if (list) {
        this.dialogService.notify("FAVORITES_LIST_EXIST");
        await this.loadFavourites();
      } else {
        if (!validation) {
          return { success: false, error: "FAVORITES_LIST_NAME_INVALID" };
        }
        let response = await this.favoriteStore.createFavoriteList(
          name,
          articles,
          uuid
        );
        await this.loadFavourites();
        return response;
      }
    } catch (error) {
      console.log("AddFavourtesList error", error);
    }
  }

  // remove a favorite list by the API
  async removeFavoritesList(favoritesListName: string) {
    try {
      let lists = this.favoritesLists$.getValue();
      let list = lists.find((favList) => favList.name === favoritesListName);
      let response = await this.favoriteStore.deleteFavoritesList(list);
      if (list.articles.length) {
        list.articles.forEach(async (article) => {
          await this.storage.remove(article.materialNumber);
        });
      }
      await this.loadFavourites();
      return response;
    } catch (error) {
      console.log("RemoveFavoritesList error ", error);
    }
  }

  // remove a archived list by the API
  async removeArchivedList(favoritesListName: string) {
    try {
      let lists = this.archivedLists$.getValue();
      let list = lists.find((favList) => favList.name === favoritesListName);
      let response = await this.favoriteStore.deleteArchivedList(list);
      // if(list.articles.length) {
      //   list.articles.forEach(async article => {
      //     await this.storage.remove(article.materialNumber);
      //   });
      // }
      await this.loadArchivedLists();
      return response;
    } catch (error) {
      console.log("RemoveFavoritesList error ", error);
    }
  }

  // update a favorite name list by the API
  async updateFavoritesList(
    oldName: string,
    newName: string
  ): Promise<{ success: boolean; error?: string }> {
    try {
      const validation = validateAlphaNumericString(newName);
      if (!validation) {
        return { success: false, error: "FAVORITES_LIST_NAME_INVALID" };
      }
      let lists = this.favoritesLists$.getValue();
      let list = lists.find((favList) => favList.name === oldName);
      let response = await this.favoriteStore.updateFavoritesList(
        list,
        newName
      );
      await this.loadFavourites();
      return response;
    } catch (error) {
      console.log("UpdateFavoritesList Error ", error);
    }
  }

  async updateOfflineUsage(favList: FavoritesList) {
    await this.favoriteStore.updateFavoritesListOfflineUsage(
      favList,
      this.expandListState
    );
  }

  // check a articles is in favorite list
  isFavorite(article: Article): boolean {
    const favoritesLists = this.favoritesLists$.getValue();
    return favoritesLists.some((favList) =>
      favList.articles.some(
        (fav) => fav.materialNumber === article.materialNumber
      )
    );
  }

  // check if there is a list by name
  listExists(name: string): boolean {
    let lists = this.favoritesLists$.getValue();
    return lists.some(
      (favList) => favList.name.toUpperCase() === name.toUpperCase()
    );
  }

  private updateFavoritesObserver(lists: FavoritesList[]) {
    this.favoritesLists$.next(lists);
    let favorites = [];
    lists.forEach((list) => {
      list.articles.forEach((article) => {
        if (
          favorites.every(
            (fav) => fav.materialNumber !== article.materialNumber
          )
        ) {
          favorites.unshift(article);
        }
      });
    });
    this.favourites$.next(favorites ? favorites : []);
  }

  // update form
  async showListUpdateForm(currentName: string) {
    try {
      const prompt = await this.alertCtrl.create({
        // header: this.translate.instant('FAVORITES_LIST_EDIT_TITLE'),
        header: this.translate.instant("EDIT_LIST"),
        message: this.translate.instant("FAVORITES_LIST_EDIT_TEXT"),
        inputs: [
          {
            name: "name",
            value: currentName,
            placeholder: this.translate.instant(
              "FAVORITES_LIST_NAME_PLACEHOLDER"
            ),
          },
        ],
        buttons: [
          {
            text: this.translate.instant("COMMON.CANCEL"),
            handler: (data) => {},
          },
          {
            text: this.translate.instant("COMMON.SAVE"),
            handler: async (data) => {
              let newName = data.name;
              let response = await this.updateFavoritesList(
                currentName,
                newName
              );
              if (response.success) {
                this.dialogService.notify("FAVORITES_LIST_EDIT_SUCCESS");
                // window.location.reload();
              } else if (response.error) {
                this.dialogService.notify(response.error);
              } else {
                this.dialogService.notify("FAVORITES_LIST_EDIT_FAIL");
              }
            },
          },
        ],
      });
      await prompt.present();
    } catch (error) {
      console.log("showListUpdateForm error");
    }
  }

  // show create list from infocategory
  async showListCreateFormFromInfo(
    onFinishDisplayListChooser = false,
    article?: Article
  ) {
    try {
      const prompt = await this.alertCtrl.create({
        header: this.translate.instant("FAVORITES_LIST_CREATE_TITLE"),
        message: this.translate.instant("FAVORITES_LIST_CREATE_TEXT"),
        inputs: [
          {
            name: "name",
            placeholder: this.translate.instant("ADD_NAME"),
          },
        ],
        buttons: [
          {
            text: this.translate.instant("COMMON.CANCEL"),
            handler: (data) => {
              if (onFinishDisplayListChooser && article) {
                this.showListChooser(article);
              }
            },
          },
          {
            text: this.translate.instant("COMMON.ADD"),

            handler: async (data) => {
              let listName = data.name;
              let lists = this.favoritesLists$.getValue();
              let list = lists.find((favList) => favList.name === listName);
              if (list) {
                this.dialogService.notify("FAVORITES_LIST_EXIST");
              } else {
                let response = await this.addFavoritesList(listName);
                if (response.success) {
                  this.dialogService.notify("FAVORITES_LIST_CREATE_SUCCESS");
                } else if (response.error) {
                  this.dialogService.notify(response.error);
                } else {
                  this.dialogService.notify("FAVORITES_LIST_CREATE_FAIL");
                }
                if (onFinishDisplayListChooser && article) {
                  this.showListChooser(article);
                }
              }
            },
          },
        ],
      });
      await prompt.present();
    } catch (error) {
      console.log("showListCreateForm error");
    }
  }

  // show create form
  async showListCreateForm(
    onFinishDisplayListChooser = false,
    article?: Article
  ) {
    try {
      const prompt = await this.alertCtrl.create({
        header: this.translate.instant("FAVORITES_LIST_CREATE_TITLE"),
        message: this.translate.instant("FAVORITES_LIST_CREATE_TEXT"),
        inputs: [
          {
            name: "name",
            placeholder: this.translate.instant("ADD_NAME"),
          },
        ],
        buttons: [
          {
            text: this.translate.instant("COMMON.CANCEL"),
            handler: (data) => {
              if (onFinishDisplayListChooser && article) {
                this.showListChooser(article);
              }
            },
          },
          {
            text: this.translate.instant("COMMON.ADD"),
            handler: async (data) => {
              let listName = data.name;
              let lists = this.favoritesLists$.getValue();
              let list = lists.find((favList) => favList.name === listName);
              if (list) {
                this.dialogService.notify("FAVORITES_LIST_EXIST");
              } else {
                let response = await this.addFavoritesList(listName);
                if (response.success) {
                  this.dialogService.notify("FAVORITES_LIST_CREATE_SUCCESS");
                  // window.location.reload();
                } else if (response.error) {
                  this.dialogService.notify(response.error);
                } else {
                  this.dialogService.notify("FAVORITES_LIST_CREATE_FAIL");
                }
                if (onFinishDisplayListChooser && article) {
                  this.showListChooser(article);
                }
              }
            },
          },
        ],
      });
      await prompt.present();
    } catch (error) {
      console.log("showListCreateForm error");
    }
  }

  public async addListPopup(
    onFinishDisplayListChooser = false,
    article?: Article
  ) {
    const modal = await this.modalCtrl.create({
      component: AddListAlertPopup,
      cssClass: "add-list-alert-popup",
    });
    return await modal.present();
  }

  // chooser list

  public async showListChooser(article: Article) {
    try {
      let inputs = this.generateInputs(article);
      if (inputs.length == 0) {
        this.loading.start();
        this.displayEmptyListPopup(article);
        this.loading.stop();
      } else {
        console.log(
          "this.favoritesLists$.getValue()",
          this.favoritesLists$.getValue()
        );
        const modal = await this.modalCtrl.create({
          component: AddListModelPopup,
          cssClass: "add-remove-list-alert-popup",
          componentProps: {
            favoriteList: this.favoritesLists$.getValue(),
            article: article,
            onDone: async (selectedLists) => {
              if (selectedLists) {
                localStorage.setItem("listName", "");
                localStorage.setItem("pageName", "favourites.service");
                await this.fullRemoveFavorite(article);
                setTimeout(() => {
                  selectedLists.forEach(async (favListName) => {
                    this.addFavourite(article, favListName);
                  });
                }, 100);
              }
            },
          },
        });
        return await modal.present();
      }
    } catch (error) {
      console.log("ShowListChooser Error");
    }
  }

  async displayEmptyListPopup(article: Article) {
    const modal = await this.modalCtrl.create({
      component: InfocategoryEmptyListPopup,
      cssClass: "delete-favorite-popup-modal Removehead",
      componentProps: {
        article,
      },
    });
    return await modal.present();
  }
  // Generates checkboxes for favorites list chooser
  private generateInputs(article: Article): any[] {
    const listName = localStorage.getItem("listName");
    let inputs = this.favoritesLists$.getValue().map((favList) => {
      return {
        type: "checkbox",
        label: this.translate.instant(favList.name),
        value: favList.name,
        checked:
          favList.articles.some(
            (art) => art.materialNumber === article.materialNumber
          ) || favList.name === listName,
      };
    });

    return inputs;
  }

  async importFavouriteList(uuid: any) {
    try {
      this.loading.start();
      let favList = await this.safetyAPIService.getFavoritesList(uuid);
      favList.articles = favList.articles.map((favItem) => {
        return { ...favItem, offlineAvailable: false };
      });
      favList.offlineAvailable = false;
      const response = await this.dialogService.confirm(
        this.translate.instant("FAVORITES_LISTS_IMPORT_MESSAGE"),
        this.translate.instant("FAVORITES_LISTS_IMPORT_TITLE") +
          ": " +
          (favList.name || "")
      );
      if (response == 1) {
        let response = await this.addFavoritesList(
          favList.name,
          favList.articles,
          uuid
        );
        if (!response.success) {
          this.loading.stop();
          await this.dialogService.notify(response.error);
          return;
        }
        await this.dialogService.notify("FAVORITES_LISTS_IMPORT_SUCCESS");
      }
      this.loading.stop();
    } catch (error) {
      this.loading.stop();
      this.dialogService.notify("FAVORITES_LISTS_INVALID_LINK");
    }
  }
}
