import { Component, Input } from "@angular/core";
import { Article, FavoritesList } from "../../types/types";
import { BehaviorSubject } from "rxjs";
import { DialogService } from "src/app/services/dialog.service";
import { ModalController } from "@ionic/angular";
import { FavouritesService } from "src/app/services/favourites.service";
@Component({
  selector: "AddListAlertPopup",
  templateUrl: "AddListAlertPopup.html",
  styleUrls: ["./AddListAlertPopup.scss"],
})
export class AddListAlertPopup {
  @Input() article: Article;
  public myTextLen = 0;
  isModalOpen = false;
  expandListState: FavoritesList[];
  private favoritesLists$: BehaviorSubject<FavoritesList[]> =
    new BehaviorSubject([]);
  favoritesLists = this.favoritesLists$.asObservable();

  private favourites$: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  favourites = this.favourites$.asObservable();
  inputModel: string;
  constructor(
    private dialogService: DialogService,
    private modalCtrl: ModalController,
    private fav: FavouritesService,
    private favoritesService: FavouritesService
  ) {}

  countChar(e) {
    if (e.trim() == "") {
      this.inputModel = "";
      e = "";
    }
    this.myTextLen = e.length;
  }

  closeWin() {
    this.modalCtrl.dismiss();
  }

  async onSaveClick(data) {
    let listName = data;
    let lists = this.favoritesLists$.getValue();
    let list = lists.find((favList) => favList.name === listName);
    if (list) {
      this.dialogService.notify("FAVORITES_LIST_EXIST");
    } else {
      let response = await this.fav.addFavoritesList(listName);
      if (response.success) {
        this.dialogService.notify("FAVORITES_LIST_CREATE_SUCCESS");
        this.modalCtrl.dismiss();
        if (this.article) {
          this.favoritesService.showListChooser(this.article);
        }
      } else {
        this.dialogService.notify("FAVORITES_LIST_CREATE_FAIL");
      }
    }
  }
}
