import { Component, Input, ViewChild } from "@angular/core";
import { IonContent, ModalController } from "@ionic/angular";
import { LoadingProvider } from "src/app/services/loading/loading";
import { EditListAlertPopup } from "../EditListAlertPopup/EditListAlertPopup";
import { DeleteAlertPopup } from "../DeleteAlertPopup/DeleteAlertPopup";
import { DeviceDetectorService } from "ngx-device-detector";
import { checkIsDesktop } from "src/app/helperFunction";

@Component({
  selector: "app-popover",
  templateUrl: "popover.component.html",
  styleUrls: ["popover.component.scss"],
})
export class PopoverComponent {
  @Input() oldName: string;
  @Input() onDismiss: (v?: boolean) => void;
  @Input() btnList: {
    showDelete: boolean;
    showEdit: boolean;
    showArchived: boolean;
  } = {
    showArchived: false,
    showDelete: true,
    showEdit: true,
  };
  private scrollPosition: number;
  @ViewChild(IonContent) content: IonContent;
  favValue: any;
  favSelectedVal: any;
  favSelectedValue: any;
  popover: any;
  public isDesktop: boolean;

  constructor(
    private loading: LoadingProvider,
    private modalCtrl: ModalController,
    private deviceService: DeviceDetectorService
  ) {
    this.isDesktop = checkIsDesktop(this.deviceService);
  }

  onClickEdit() {
    this.showEditModal();
  }

  onClickDelete() {
    this.showDeleteModal();
  }
  onClose() {
    this.onDismiss();
  }

  async showEditModal() {
    try {
      this.favSelectedVal = this.favValue.name;

      this.loading.start();
      await this.editListPopup(this.favSelectedVal);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      console.log("error-->" + error);
      this.loading.stop();
    }
  }

  public async editListPopup(favSelectedValue) {
    this.popover.dismiss();
    const modal = await this.modalCtrl.create({
      component: EditListAlertPopup,
      cssClass: "edit-list-popup-modal",
      componentProps: {
        favSelectedValue: this.favSelectedValue,
        oldName: this.oldName,
        onDismiss: this.onDismiss,
        onSearchClear: () => {
          this.popover.dismiss();
        },
      },
    });
    return await modal.present();
  }

  async showDeleteModal() {
    try {
      this.favSelectedVal = this.favValue.name;
      const pgName = "popover";
      this.loading.start();
      await this.deleteFavorite(pgName);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      console.log("error-->" + error);
      this.loading.stop();
    }
  }

  public async deleteFavorite(pgName) {
    this.popover.dismiss();
    const modal = await this.modalCtrl.create({
      component: DeleteAlertPopup,
      cssClass: "delete-favorite-popup-modal",
      componentProps: {
        favSelectedValue: this.favSelectedValue,
        oldName: this.oldName,
        pgName: pgName,
        onDismiss: this.onDismiss,
        onSearchClear: () => {
          this.popover.dismiss();
        },
      },
    });
    return await modal.present();
  }
  public async onClickArchived() {
    this.onDismiss(true);
  }
  private retainScrollPosition() {
    setTimeout(() => {
      this.content.scrollToPoint(0, this.scrollPosition);
    }, 0);
  }
}
