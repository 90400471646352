import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { TokenService } from "./token.service";
import { Observable, Subject } from "rxjs";
import {
  InAppBrowser,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser/ngx";
import { UtilProvider } from "../services/util/util";
import { SafetyDataAPI } from "./safety-data-api.service";
import { map } from "rxjs/operators";
import {
  LoginCredentials,
  UserRegistration,
  CodeConfirmation,
  ResetPassword,
  UserData,
} from "../classes/_interfaces";
import { ConnectivityProvider } from "../services/connectivity/connectivity";
import { DataSyncService } from "./data-sync.service";
import { SettingsService } from "./settings.service";
import { StorageService } from "./storage.service";
import { Settings } from "../types/types";
import { storageKeys } from "./storage-keys";
import { splitName } from "../helperFunction";
import { environment } from "../env/env";
import { Capacitor } from "@capacitor/core";
import { Events } from "./events/events";
import { DialogService } from "./dialog.service";

@Injectable({
  providedIn: "root",
})
export class AuthentService {
  public currentUser: any;
  public token: string;
  private serviceRoot = "/service/user";
  public userId: any;
  public userData: UserData = {
    firstName: "",
    lastName: "",
    email: "",
    userId: 0,
  };

  private inAppBrowserOptions: InAppBrowserOptions = {
    location: "yes",
    hidden: "no",
    hidenavigationbuttons: "yes",
    hideurlbar: "yes",
    zoom: "no",
  };

  constructor(
    public httpService: HttpService,
    private tokenService: TokenService,
    private iab: InAppBrowser,
    public utils: UtilProvider,
    private events: Events,
    public safetyDataAPI: SafetyDataAPI,
    private storage: StorageService,
    public connectivyService: ConnectivityProvider,
    private dataSyncService: DataSyncService,
    private dialogService: DialogService,
    private settingsService: SettingsService
  ) {}

  public loginCred$ = new Subject();
  public logoutCred$ = new Subject();

  /**
   * Calls the get method from the tokenService and
   * returns an Observable whichs transform the token
   * from a string to a boolean. True if the token is not
   * undefined, therefore the user is authenticated if the
   * token is not undefined.
   */
  public authenticated(): Observable<string> {
    return this.tokenService.get().pipe(map((token) => token));
  }

  public listenAuthentication(): Observable<boolean> {
    return this.tokenService.getContinuous().pipe(map((value) => !!value));
  }

  public getUserId(): void {
    this.storage.get(storageKeys.userId).subscribe((userId) => {
      this.userId = userId;
    });
  }

  // Cached or new
  public getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.currentUser) {
        resolve(this.currentUser);
      } else {
        this.httpService.get(this.serviceRoot).then(
          (response) => {
            if (response && response.user) {
              this.currentUser = response.user;
              resolve(response.user);
            } else reject("Invalid token");
          },
          (err) => {
            console.error(err.message);
          }
        );
      }
    });
  }

  public getUserData(id: number, token: any): Promise<any> {
    return this.httpService.getUserData("/user/id", id, token);
  }

  public checkUserDataAndUserDeleted(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.get(storageKeys.userId).subscribe(
        async (id) => {
          try {
            const response = await this.httpService.getUserData(
              "/user/id",
              id,
              token
            );
            this.setUserData(response.user);
            resolve("success");
          } catch (error) {
            if (error && error.status == 403) {
              reject("User account is deleted");
            } else {
              resolve("Error due to some other reason");
            }
          }
        },
        (error) => {
          resolve("success");
        }
      );
    });
  }

  /* Checks if a user is in place */
  public checkUser(): Promise<boolean> {
    return Promise.resolve(!!this.currentUser);
  }

  public async login(credentials: LoginCredentials): Promise<any> {
    const response = await this.safetyDataAPI.login(credentials);
    if (!response.token) {
      return Promise.reject(response);
    }
    this.setUserData(response);
    this.token = response.token;
    this.storage.set(storageKeys.userId, response.id);
    this.tokenService.set(response.token);
    await this.dataSyncService.checkForDataChanges();
    return { token: this.token, user: this.currentUser };
  }

  public setUserData(response: any) {
    const { firstName = "", lastName = "" } = splitName(response.name);
    if (response && response.alternateEmail) {
      this.userData.email = response.alternateEmail;
    } else {
      let tempEmail = response.email || "";
      this.userData.email = tempEmail.includes("@appleId.com") ? "" : tempEmail;
    }
    this.userData.userId = response.id;
    this.userData.firstName = firstName.trim();
    this.userData.lastName = lastName.trim();
  }

  public async loginWithToken(token: string) {
    this.token = token;
    this.tokenService.set(token);
    await this.dataSyncService.checkForDataChanges();
    return { token: this.token, user: this.currentUser };
  }

  public async registerWithToken(token: string) {
    this.token = token;
    this.tokenService.set(token);
    await this.dataSyncService.checkForDataChanges();
    return { token: this.token, user: this.currentUser };
  }

  /**
   * Sets the current user to null and removes
   * the token from the token service.
   */
  public async logout(): Promise<void> {
    return new Promise((resolve) => {
      this.currentUser = null;
      this.userData = {
        firstName: "",
        lastName: "",
        email: "",
        userId: 0,
      };

      this.events.publish("favorites:unload");
      this.storage.remove(storageKeys.favoritesLists);
      this.storage.remove(storageKeys.userId);
      this.storage.remove(storageKeys.showSafetyTagHint);
      this.storage.remove(storageKeys.showPopOverSafetyTag);
      this.tokenService.remove();

      this.storage.get(storageKeys.settings).subscribe(async (settings) => {
        if (Capacitor.getPlatform() != "web") {
          this.dialogService.appRestartToast("LANGUAGE_CHANGE");
        }
        if (settings) {
          settings.appLanguage = environment.defaultLanguage;
          this.settingsService.updateSettings(settings);
          this.storage.set(storageKeys.fallbackLang, settings.appLanguage);
        } else {
          this.settingsService.updateSettings(new Settings());
        }
        resolve();
      });
    });
  }

  public async askForPasswordReset(mail: string): Promise<any> {
    try {
      const response = await this.httpService.post(
        "/service/sessionHandling/askForPasswordReset",
        { mail: mail }
      );
      return response.data.info;
    } catch (e) {
      return -1;
    }
  }

  public async resetPassword(
    mail: string,
    password: string,
    resetkey: string
  ): Promise<any> {
    var data = {
      mail: mail,
      password: password,
      resetkey: resetkey,
    };

    try {
      const response = await this.httpService.put(
        "/service/sessionHandling/resetPassword",
        data
      );
      return response.data.check;
    } catch (e) {
      return false;
    }
  }

  public forgotPassword(
    email: string,
    recaptcha: string,
    identifier: string
  ): Promise<any> {
    return this.safetyDataAPI.forgotPassword(email, recaptcha, identifier);
  }

  public resetPasswordForgot(resetPassword: ResetPassword): Promise<any> {
    return this.safetyDataAPI.resetPassword(resetPassword);
  }

  public register(userRegisterData: UserRegistration): Promise<any> {
    return this.safetyDataAPI.register(userRegisterData);
  }

  public verifySignup(codeConfirmData: CodeConfirmation): Promise<any> {
    return this.safetyDataAPI.verifySignup(codeConfirmData);
  }

  /* Triggered by sessionService on siteLoad */
  /* Used for ionNavGuards */
  public handleSiteLoad(): Promise<boolean> {
    return this.tokenService
      .get()
      .pipe(map((token) => !!token))
      .toPromise();
  }

  public loginWithLinkedInWithWeb(): Promise<any> {
    const customURL = `${this.safetyDataAPI.linkedInAuthorizeURL}&loginURL=${environment.redirectURI}`;
    this.iab.create(customURL, "_self");
    return Promise.resolve();
  }

  // Browser listener, for URL redirection
  public loginWithAppleInAndroid(): Promise<any> {
    return new Promise((resolve, reject) => {
      const customURL = `https://appleid.apple.com/auth/authorize?client_id=${environment.clientId}&redirect_uri=${environment.redirectURI}&response_type=code id_token&response_mode=fragment`;

      const browserRef = this.iab.create(
        customURL,
        "_blank",
        this.inAppBrowserOptions
      );

      let promiseHandled = false;

      // to check the page has loaded or not
      browserRef.on("loadstart").subscribe((event) => {
        // if (event.url.indexOf(`${environment.redirectURI}#`) === 0) {
        if (event.url.includes(`#code`)) {
          browserRef.close();
          promiseHandled = true;
          let parameters = this.utils.obtainParametersFromAppleURL(event.url);

          if (parameters["code"]) {
            this.appleAuhthorizationCode(parameters).then(
              (response) => resolve(response),
              (error) => reject(error)
            );
          } else {
            reject({ error: "ERROR_MESSAGE_APP" });
          }
        }
      });

      // to check the page has loaded having some error.
      browserRef.on("loaderror").subscribe((event) => {
        if (event.message === "net::ERR_INTERNET_DISCONNECTED") {
          browserRef.close();
          promiseHandled = true;
          reject({ error: "INTERNET_DISCONNECTED" });
        }
      });
      browserRef.on("exit").subscribe((event) => {
        if (!promiseHandled) {
          reject("in app browser exit");
        }
      });
    });
  }

  /**
   * Log in the application by using the LinkedIn credentials.
   *
   * @todo Implement load errors.
   */
  public loginWithLinkedIn(): Promise<any> {
    return new Promise((resolve, reject) => {
      const browserRef = this.iab.create(
        this.safetyDataAPI.linkedInAuthorizeURL,
        "_blank",
        this.inAppBrowserOptions
      );

      let promiseHandled = false;

      let timeoutConnection = setTimeout(() => {
        console.error("Time expired when setting connection with LinkedIn");
        browserRef.close();
        reject({ error: "ERROR_TIMEOUT_CONNECTION" });
      }, 10000);

      /* The inAppBroswer is shown only after LinkedIn page is fully loaded.
          If the user has an active session in LinkedIn, the inAppBrowser will not
          fall in this case. */
      browserRef.on("loadstop").subscribe((event) => {
        if (event.url.indexOf(this.safetyDataAPI.linkedInURL) === 0) {
          browserRef.show();
        }
      });

      /* Once the localhost callback is in the inAppBrowser, close it and make the communication
          between our server and linkedin in order to obtain a Token for login. */
      browserRef.on("loadstart").subscribe((event) => {
        if (event.url.indexOf(this.safetyDataAPI.linkedInURL) === 0) {
          clearTimeout(timeoutConnection);
        }

        if (
          event.url.indexOf(this.safetyDataAPI.linkedInAuthorizationURL) === 0
        ) {
          browserRef.close();
          promiseHandled = true;
          let parameters = this.utils.obtainParametersFromURL(event.url);

          if (parameters["code"]) {
            this.loginWithLinkedInAuthorizationCode(parameters["code"]).then(
              (response) => resolve(response),
              (error) => reject(error)
            );
          } else {
            reject({ error: "LINKEDIN_" + parameters["error"].toUpperCase() });
          }
        }
      });
      browserRef.on("loaderror").subscribe((event) => {
        if (event.message === "net::ERR_INTERNET_DISCONNECTED") {
          browserRef.close();
          promiseHandled = true;
          reject({ error: "INTERNET_DISCONNECTED" });
        }
      });
      browserRef.on("exit").subscribe((event) => {
        if (!promiseHandled) {
          reject("in app browser exit");
        }
      });
    });
  }

  /**
   * Handles the communication between the client, the server and Linkedin until
   * a token is obtained and the login is done.
   *
   * @param authorizationCode authorizationCode returned from LinkedIn
   */
  public async loginWithLinkedInAuthorizationCode(
    authorizationCode: string
  ): Promise<any> {
    let postRequest = { authorizationCode: authorizationCode };

    try {
      const loginResponse = await this.safetyDataAPI.linkedInLogin(postRequest);
      this.loginCred$.next(loginResponse);
      // this.loginCred$.complete();
      this.setUserData(loginResponse);
      this.storage.set(storageKeys.userId, loginResponse.id);
      await this.loginWithToken(loginResponse.token);
      return Promise.resolve(loginResponse);
    } catch (errorResponse) {
      return Promise.reject(errorResponse);
    }
  }

  public async signInWithApple(userData: any): Promise<any> {
    const email = `${userData.user}@appleId.com`;
    let postRequest: any = {
      authorizationCode: userData.authorizationCode,
      identityToken: userData.identityToken,
      email,
      name: `${userData.fullName.givenName} ${userData.fullName.familyName}`.trim(),
      alternateEmail: userData.email,
    };

    if (Capacitor.getPlatform() == "ios") {
      postRequest.requestFrom = environment.requestFrom;
    } else {
      postRequest.requestFrom = environment.webRequestFrom;
    }

    try {
      const appleResponse = await this.safetyDataAPI.signInWithApple(
        postRequest
      );
      appleResponse.name = (
        appleResponse.name ||
        appleResponse.id ||
        ""
      ).toString();
      this.loginCred$.next(appleResponse);
      // this.loginCred$.complete();
      this.setUserData(appleResponse);
      this.storage.set(storageKeys.userId, appleResponse.id);
      await this.loginWithToken(appleResponse.token);
      return Promise.resolve(appleResponse);
    } catch (errorResponse) {
      return Promise.reject(errorResponse);
    }
  }

  async getUserIdentity(token: string) {
    try {
      let payload = atob(token.split(".")[1]);
      payload = JSON.parse(payload);
      return Promise.resolve(payload.sub);
    } catch (Error) {
      return Promise.reject(false);
    }
  }

  async appleAuhthorizationCode(response: any) {
    try {
      const userIdentitytkn = await this.getUserIdentity(response.id_token);
      if (userIdentitytkn) {
        const userData: any = {
          user: userIdentitytkn,
          authorizationCode: response.code,
          identityToken: response.id_token,
        };
        userData.email = "";
        const fullName: any = {
          givenName: "",
          familyName: "",
        };
        userData.fullName = fullName;
        await this.signInWithApple(userData);
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject();
    }
  }
}
