import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { Article, HistoryArticle } from "../types/types";
import { storageKeys } from "./storage-keys";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root",
})
export class HistoryService {
  private history$: BehaviorSubject<HistoryArticle[]> = new BehaviorSubject([]);

  history = this.history$.asObservable();

  constructor(public storage: Storage) {}

  async loadHistory() {
    const history = await this.storage.get(storageKeys.history);
    this.history$.next(history ? history : []);
  }

  async addHistory(article: Article) {
    const currentHistory = this.history$.getValue();
    const historyIndex = currentHistory.findIndex(
      (his) => his.materialNumber === article.materialNumber
    );

    let newHistory: HistoryArticle[];
    const historyArticle = this.createHistoryArticle(article);
    if (historyIndex == -1) {
      newHistory = [historyArticle, ...currentHistory];
    } else {
      currentHistory[historyIndex] = historyArticle;
      newHistory = this.sortHistoryItemsByDate(currentHistory);
    }

    this.history$.next(newHistory);
    await this.saveHistory(newHistory);
  }

  async deleteHistory(history: HistoryArticle) {
    const filteredHistory = this.history$
      .getValue()
      .filter((his) => his.materialNumber != history.materialNumber);
    this.history$.next(filteredHistory);
    await this.saveHistory(filteredHistory);
  }

  async clearHistory() {
    this.history$.next([]);
    return await this.storage.remove(storageKeys.history);
  }

  private saveHistory(history: HistoryArticle[]) {
    return this.storage.set(storageKeys.history, history);
  }

  private createHistoryArticle(article: Article): HistoryArticle {
    return {
      articleNumber: article.articleNumber,
      articleName: article.articleName,
      casNumber: article.casNumber,
      substance: article.substance,
      system: article.system,
      materialNumber: article.materialNumber,
      batchNumber: article.batchNumber,
      date: Date.now(),
    };
  }

  private sortHistoryItemsByDate(
    historyItems: HistoryArticle[]
  ): HistoryArticle[] {
    return historyItems.sort((a, b) => (a.date < b.date ? 1 : -1));
  }

  async setHistoryData(history: HistoryArticle[]) {
    this.history$.next(history ? history : []);
    await this.storage.set(storageKeys.history, history);
  }
}
