import { Component, Injectable, ViewChild, ViewChildren } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Observable, Subscription} from 'rxjs';
import { FavoritesList, Article, SafetyDataSheet,SearchResult } from '../types/types';
import { FavouritesService } from '../services/favourites.service';
import { DialogService } from '../services/dialog.service';
import { LoadingProvider } from '../services/loading/loading';
import { SDSService } from '../services/sds.service';
import {Router, NavigationExtras, NavigationEnd} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import { TokenService} from '../services/token.service';
import { Storage } from '@ionic/storage';
import { ConnectivityProvider } from '../services/connectivity/connectivity';
import { DisableTimer, Degrees, DynamicTemplateClass } from '../constants';
import {TagsService} from '../services/tags.service';
import { environment } from '../env/env';
import { ExternalLinkService } from '../services/external-link.service';
import { SettingsService } from '../services/settings.service';
import { CN } from '../constants';
import { MQuantStripModal } from '../components/mQuantStripModal/mQuantStripModal';
import { tap, map, } from 'rxjs/operators';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../components/popoverone/popover.component';
import { DeleteAlertPopup } from '../components/DeleteAlertPopup/DeleteAlertPopup';
import { DeviceDetectorService } from "ngx-device-detector";
import { checkIsDesktop, getAppleStoreUrl, getPlayStoreUrl } from '../helperFunction';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'page-favorites-lists',
  templateUrl: 'favorites-lists.html',
  styleUrls: ['favorites-lists.scss'],
})
export class FavoritesListsPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChildren('favItem') favoritesItems;
  currentPlatform = Capacitor.getPlatform();

  public editable: boolean = false;
  public favoritesLists: Observable<FavoritesList[]>;
  public archivedList: Observable<FavoritesList[]>;
  public expandedList: FavoritesList;
  private favStorageLimit = 100; // need to read from api or else enviornment variable
  private logged = false;
  private scrollPosition: number;
  disabledForWhile = false;
  public favCount: number;
  public archCount: number=0;
  public inputModel = "";
  chinaWebPortalEnabled = environment.validityArea == CN;
  public searchTerm: string = '';
  searchSubscription: Subscription;
  public isNoSearchText = false;
  public dataValue:boolean = false;
  showScroll: boolean;
  public searchResults: Article[];
  public searchHits = -1;
  public help: boolean = true;
  private searchLimit = 20;
  public searchOffset = 0;
  toTop: boolean = false;
  public isDesktop: boolean;
  public isAchivedPage:boolean;
  
  constructor(private router: Router,
    private deviceService: DeviceDetectorService,
    private favoritesService: FavouritesService,
    private sdsService: SDSService,
    private dialogService: DialogService,
    private connectivity: ConnectivityProvider,
    private storage: Storage,
    private tokenService: TokenService,
    private tagsService: TagsService,
    private settingsService: SettingsService,
    public modalCtrl: ModalController,
    public external: ExternalLinkService,
    public popoverController: PopoverController,
    private loading: LoadingProvider) {
    this.favoritesLists = this.favoritesService.favoritesLists;
    this.favoritesService.favoritesLists$.subscribe(favoritesLists => this.favCount = favoritesLists.length)
    //@ts-ignore
    this.favCount = this.favoritesLists?.source?._value?.length;
    this.archivedList = this.favoritesService.archivedLists;
    this.favoritesService.archivedLists$.subscribe(archivedLists => this.archCount = archivedLists.length)
    this.sdsService.showScroll$.subscribe(() => {
      const element = document.getElementById("containerId");
      element.scrollIntoView();
    });
    
    this.sdsService.showScroll$.subscribe(()=> {
      const element = document.getElementById("containerId");
      element.scrollIntoView();
    });

    this.isDesktop = checkIsDesktop(this.deviceService);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elm = window.document.querySelector('ion-content').shadowRoot.querySelector('.inner-scroll.scroll-y');
      if (elm) {
        elm.addEventListener("scroll", (e) => {
          this.toTop = (e.target as any)?.scrollTop > 0;
        });
      }
    }, 1000);
  }

  async ngOnInit() {
    if (Capacitor.getPlatform() == 'web') {
      this.tokenService.get().subscribe( async value => {
        this.logged = !!value;
        const urlArray = this.router.url.split('=');
        if(urlArray.length > 1 ) {
          const uuid = urlArray.pop();
          if (!!value) {
            await this.favoritesService.importFavouriteList(uuid);
          } else {
            await this.dialogService.notify('IMPORT_FAVOURITE_IF_LOGGED_IN');
          }
        }
      });
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          if (event['url'] == '/favoriteLists-achived' ) {
            this.isAchivedPage = true; 
          }else {
            this.isAchivedPage = false;   
          }
        }
      });
      this.sdsService.renderFooter.next(true);
  }
 
  ionViewDidEnter() {
    setTimeout(() => {
      this.disabledForWhile = false;
    }, DisableTimer);
  }
  
  logScrollEnd() {
    this.content.getScrollElement().then(data => {
      this.scrollPosition = data.scrollTop;
    });
  }

  toggleEditable(): void {
    this.editable = !this.editable;
  }

  backToTop(){
    const element = document.getElementById("containerId");
    element.scrollIntoView();
}
private prepareSearch() {
  this.loading.start();
  this.searchResults = null;
  this.searchHits = -1;
  this.help = false;
}

public searchArticles(val: string) {        
  this.searchTerm = val;
  if (this.searchTerm && this.searchTerm.length > 2) {
      this.prepareSearch();
      this.searchSubscription && this.searchSubscription.unsubscribe();
      this.searchSubscription = this.performSearchArticles(0)
          .pipe(
              tap(result => this.handleSearchResult(result)),
              map(result => result.results))
          .subscribe(
              results => {
                  if(results.length > 0) {
                      this.isNoSearchText = false
                      this.dataValue = false
                  }else if(results.length == 0) {
                      this.dataValue = true
                      this.isNoSearchText = false
                  }
                  return this.searchResults = results.filter(item => {                            
                      if (item.articleNumber) {
                          item.articleNumber = item.articleNumber.replace("###", ' ');
                      }
                      // return this.filterOut_Bulk_Var_0000_9999(item);
                      return item;
                  });
              },
              (err) => this.onSearchError(err)
          );
  } else {
      this.resetSearchResults();
  }
}

private handleSearchResult(searchResult: SearchResult) {
  this.loading.stop();
  if (searchResult) {
      this.searchHits = searchResult.hits ? searchResult.hits : 0;
      if (searchResult.results && searchResult.results.length > 0) {
          this.hideHelp();
          if (searchResult.hits > this.searchLimit) {
              // this.showToManyResultsToast();
          } else if (searchResult.hits < this.searchLimit) {
              this.searchOffset = -1;
          }
      }
  }
}

private hideHelp() {
  this.help = false;
}

private resetSearchResults() {
  this.loading.stop();
  this.isNoSearchText = true;
  this.searchResults = null;
  this.searchHits = -1;
}

private onSearchError(error = null) {
  this.loading.stop();
  this.searchResults = null;
  this.searchHits = 0;
  this.searchTerm = ''
  this.dialogService.critical('UNEXPECTED_ERROR', 'ERROR_MESSAGE_APP');
}


private performSearchArticles(offset: number): Observable<SearchResult> {
  this.searchOffset = offset;
  return this.sdsService.searchArticleByMaterialNumberAndLimit(null, this.searchTerm, this.searchLimit, this.searchOffset)
}

  async onSaveClick(favData?: any){
    let listName = this.inputModel;
    this.inputModel = ""
    let response = await this.favoritesService.addFavoritesList(listName);
    
    if (response.success) {
      this.dialogService.notify('FAVORITES_LIST_CREATE_SUCCESS');
      this.inputModel = '';
      // window.location.reload();
    } else if (response.error) {
      this.dialogService.notify(response.error);
    } else {
      this.dialogService.notify('FAVORITES_LIST_CREATE_FAIL');
    }    
    this.favoritesService.showListChooser(favData.article)
    this.inputModel = ""
  }
  
  async deleteList(list: FavoritesList) {
    if (await this.dialogService.confirm('FAVORITES_DELETE_CONFIRM_TITLE', 'FAVORITES_LIST_DELETE_SELECTED_CONFIRM_MESSAGE')) {
      try {
        this.loading.start();
        await this.favoritesService.removeFavoritesList(list.name);
        this.retainScrollPosition();
        this.loading.stop();
      } catch (error) {
        this.loading.stop();
      }
    }
  }

  async deleteFavorite(favData: any) {
    if (await this.dialogService.confirm('FAVORITES_DELETE_CONFIRM_TITLE', 'FAVORITES_DELETE_CONFIRM_MESSAGE')) {
      try {
        this.loading.start();
        await this.favoritesService.removeFavoriteFrom(favData.article, favData.favList.name);
        this.retainScrollPosition();
        this.loading.stop();
      } catch (error) {
        this.loading.stop();
      }
    }
  }

  //Archived delete
  async deleteArchiveList(list: FavoritesList) {
      try{
        this.loading.start();
        const pgName = "archListById"
        this.deleteAllFavorite(pgName, list,'',true);
        this.retainScrollPosition();
        this.loading.stop();
      } catch (error) {
        console.log("error-->"+error)
        this.loading.stop();
      }
  }
//Archived delete 
  async deleteArchive(favData: any) {
    try{
      this.loading.start();
      const pgName = "arch-list-item"
      this.deleteAllFavorite(pgName, favData,'',true);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      console.log("error-->"+error)
      this.loading.stop();
    }

  }

  async presentPopover(e: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass:'favorite-popup', 
      componentProps: {
        btnList:{
        showArchived: true,
        showEdit: false,
        showDelete: false
        }, favValue:  {}, 
        onDismiss: (val) => {
          popover.dismiss(); 
          if(val){
            this.redirectToArchivedPage();
          }
        }, oldName:''
      },
      event: e,
    });
    await popover.present();
    
  }

  async showCreateModal() {
    try {
      this.loading.start();
      await this.favoritesService.addListPopup();
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      this.loading.stop();
    }

  }

  onCreateListMobile(){
    this.router.navigateByUrl('/createaddlist', {replaceUrl: true})
  }

  async showEditModal(list: FavoritesList) {
    try {
      this.loading.start();
      await this.favoritesService.showListUpdateForm(list.name);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      this.loading.stop();
    }
  }

  public showArticle(selectedArticle: Article) {
    this.loading.start();
    if (this.connectivity.checkNetworkConnectivity()) {
        this.sdsService.loadCurrentArticleDetails(selectedArticle)
          .subscribe((sds) => {
              this.loading.stop();
              if (sds) {
                  this.navigateToInfoCategory(selectedArticle, sds);
              } else {
                this.dialogService.critical('ERROR_TITLE_SDS', 'ERROR_MESSAGE_SDS');
              }
          }, (error) => {
              this.dialogService.critical('ERROR_TITLE_SDS', 'ERROR_MESSAGE_SDS');
              this.loading.stop();
          });
    } else {
      this.storage.get(selectedArticle.materialNumber).then(sds => {
        this.loading.stop();
        if(sds) {
          this.navigateToInfoCategory(selectedArticle, sds);
        } else {
          this.dialogService.critical('ERROR_TITLE_SDS', 'offline_notification');
        }
      }, error => {
        this.loading.stop();
        this.dialogService.critical('ERROR_TITLE_SDS', 'offline_notification');
      });
    }
  }

  private navigateToInfoCategory(selectedArticle: Article, sds: SafetyDataSheet) {
    let extras: NavigationExtras = {
      state: {
        selectedArticle: selectedArticle,
        sds: sds
      },
    }
    this.navigateToNavigationExtras('infocategory', extras)
  }

  public printList(favList: FavoritesList) {
    let articles = [...favList.articles];
    const customArticles = {
      template: "big",
      amount: "",
      fillType: "",
      materialDate: "",
      extraText: "",
      ngClass: DynamicTemplateClass.big,
      rotated_by: Degrees.zero,
      rotateByClass: DynamicTemplateClass.rotated_0,
    }
    articles = articles.map((article, index) => {
      return {...article, ...customArticles}
    });
    this.tagsService.setArticles(articles);
    let extras: NavigationExtras = {
      state: {
        articles: articles,
        listName: favList.name
      }
    }
    this.navigateToNavigationExtras('tagGenerator', extras)
  }

  private navigateToNavigationExtras(page: string, extras: NavigationExtras) {
    if(this.disabledForWhile) {
      return;
    }
    this.disabledForWhile = true;
    this.router.navigate([page], extras);
  }

  public updateExpandedList(selectedList: FavoritesList) {
    let favList;
    this.favoritesLists.forEach(list => {
      favList = list;
    });
    favList = favList.map(favItem => {
        if(!this.logged && selectedList.name == favItem.name) {
          return {...favItem, expandList: !favItem?.expandList};
        } else if(this.logged && selectedList.id == favItem.id) {
            return {...favItem, expandList: !favItem?.expandList};
        } else {
          return favItem;
        }
      });
    this.favoritesService.expandFavouritLists(favList);
  }

  public updateArchiveExpandedList(selectedList: FavoritesList) {
    let archList;
    this.archivedList.forEach(list => {
      archList = list;
    });
    archList = archList.map(favItem => {
        if(!this.logged && selectedList.name == favItem.name) {
          return {...favItem, expandList: !favItem?.expandList};
        } else if(this.logged && selectedList.id == favItem.id) {
            return {...favItem, expandList: !favItem?.expandList};
        } else {
          return favItem;
        }
      });
    this.favoritesService.expandArchivesLists(archList);
  }

  async clearAllLists() {
    try{
      this.loading.start();
      const pgName = "favList"
      this.deleteAllFavorite(pgName);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      console.log("error-->"+error)
      this.loading.stop();
    }
  }

  public async deleteAllFavorite(pgName, favSelectedValue:any='', oldName:any='', fromArchive:boolean=false){
    const modal = await this.modalCtrl.create({
      component: DeleteAlertPopup,
      cssClass  : 'delete-all-favorite-popup-modal',
      componentProps: {
        favSelectedValue:favSelectedValue,
        oldName:oldName,
        pgName: pgName,
        fromArchive:fromArchive,
        onDismiss:()=>{
          if((pgName === 'archList' || pgName === 'archListById') && this.archCount == 0){
            this.redirectToFavoritePage();
          }
        }
      }
    });
    return await modal.present();
  }

async redirectToArchivedPage() {
  this.router.navigateByUrl('/favoriteLists-achived');
}

async redirectToFavoritePage() {
  this.router.navigateByUrl('/favoriteLists');
}

async clearAllAchivedLists() {
  try{
    this.loading.start();
    const pgName = "archList"
    this.deleteAllFavorite(pgName,'','',true);
    this.retainScrollPosition();
    this.loading.stop();
  } catch (error) {
    console.log("error-->"+error)
    this.loading.stop();
  }
}

  private calculateStorageLimit(): number {
    let materialSet = new Set();
    this.favoritesLists.forEach(favList => {
      favList.forEach(favItem => {
        favItem.articles.forEach(article => {
          if(article.offlineAvailable) {
            materialSet.add(article.materialNumber);
          }
        });
      });
    });
    let materialList = Array.from(materialSet);
    return materialList.length;
  }

  private calculateStorageLimitForBuilkList(selectedList: FavoritesList): number {
    let materialSet = new Set();
    let favList;
    this.favoritesLists.forEach(list => {
      favList = list;
    });
    favList.forEach(favItem => {
      favItem.articles.forEach(article => {
        if(!this.logged && selectedList.name != favItem.name && article.offlineAvailable) {
          materialSet.add(article.materialNumber);
        } else if(this.logged && selectedList.id != favItem.id && article.offlineAvailable) {
          materialSet.add(article.materialNumber);
        } 
      });
    });
    selectedList.articles.forEach(article => {
      materialSet.add(article);
    });
    let materialList = Array.from(materialSet);
    return materialList.length;
  }

  private convertObserFavListToArray(): FavoritesList[] {
    let favList:FavoritesList[];
    this.favoritesLists.forEach(list => {
      favList = list;
    });
    return favList;
  }

  updateBulkDownloadOffline(selectedList) {
    this.updateBulkDownload(selectedList, true);
  }

  updateBulkDownloadOnline(selectedList) {
    this.updateBulkDownload(selectedList, false);
  }

  async updateBulkDownload(selectedList: FavoritesList, toggleStatus: boolean) {
    try {
      if (this.connectivity.checkNetworkConnectivity()) {
        this.loading.start();
        let sum = 0;
        if (toggleStatus) {
          sum = this.calculateStorageLimitForBuilkList(selectedList);
        }
        if(sum > this.favStorageLimit && toggleStatus) {
          this.dialogService.critical('ERROR_TITLE_STORAGE', 'offline_storage_exceeds');
        } else {
          let favLists = this.convertObserFavListToArray();
          selectedList.articles.forEach(outerArticle => {
              favLists.forEach((favList, outerIndex) => {
                favList.articles.forEach((article, index) => {
                  if(article.materialNumber == outerArticle.materialNumber) {
                    favLists[outerIndex].articles[index].offlineAvailable = toggleStatus;
                  }
                });
              });
          });
          await this.favoritesService.createMultiplesFavoritesList(favLists);
          if(toggleStatus) {
            await this.dialogService.notify('product_download_status');
          }
        }
        this.loading.stop();
      } else {
        this.dialogService.critical('INTERNET_DISCONNECTED', 'offline_notification');
      }
    } catch (error) {
      this.loading.stop();
      this.dialogService.critical('ERROR_TITLE_SDS', 'ERROR_MESSAGE_SDS');
    }

  }

  async storeListForOfflineUsage(favData: { article: Article, favList: FavoritesList }) {
    try {
      if (this.connectivity.checkNetworkConnectivity()) {
        this.loading.start();
        const toggleStatus = !favData.article.offlineAvailable;
        let sum;
        if(toggleStatus) {
          sum = this.calculateStorageLimit();
        }
        if(sum >= this.favStorageLimit && toggleStatus) {
          this.dialogService.critical('ERROR_TITLE_STORAGE', 'offline_storage_exceeds');
        } else {
          let favList = this.convertObserFavListToArray();
          favList = favList.map(favList => {
            favList.articles = favList.articles.map(article => {
              if(article.materialNumber == favData.article.materialNumber) {
                return {...article, offlineAvailable: !favData.article.offlineAvailable}
              }
              return article;
            });
            return favList;
          });
          await this.favoritesService.createMultiplesFavoritesList(favList);
          if(toggleStatus) {
            await this.dialogService.notify('product_download_status');
          }
        }
        this.loading.stop();
      } else {
        this.dialogService.critical('INTERNET_DISCONNECTED', 'offline_notification');
      }
    } catch (error) {
      this.loading.stop();
      this.dialogService.critical('ERROR_TITLE_SDS', 'ERROR_MESSAGE_SDS');
    }
  }

  private retainScrollPosition() {
    setTimeout(() => {
      this.content.scrollToPoint(0, this.scrollPosition);
    }, 0);
  }

  //Web Footer Functions

  public navigateToPlayStore () {
    this.external.openExternalUrl(getPlayStoreUrl());    
  }

  public navigateToAppleStore () {
      let country = environment.validityArea.toLowerCase();
      let settingUnsubscribe = this.settingsService.settings.subscribe((settingInfo) => {
          country = settingInfo.validityAreaLanguage.validityArea.toLowerCase();
      });
      settingUnsubscribe.unsubscribe();
      this.external.openExternalUrl(getAppleStoreUrl(country));
  }

  dataPrivacyClick(){
      this.router.navigateByUrl('/impressum', {replaceUrl: true})
  }

  userGuideClick(){
      this.router.navigateByUrl('/userguide', {replaceUrl: true})
  }

  custServicesClick(){
      this.router.navigateByUrl('/contact', {replaceUrl: true})
  }

  async openMQuiantPopup() {        
    const modal = await this.modalCtrl.create({
        component: MQuantStripModal,
        cssClass  : 'm-quant-strip-modal'
      });
      return await modal.present();
  }

   // Navigates to external link on clik of ICP footer for CN
   navigateToICPLink(): void {
    this.external.openExternalUrl('https://beian.miit.gov.cn')
  }
}
