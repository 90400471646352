import { Platform, NavController, ModalController } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { HistoryService } from "./services/history.service";
import { FavouritesService } from "./services/favourites.service";
import { ValidityAreaLanguagesProvider } from "./services/validity-area-languages/validity-area-languages";
import { ChangeDetectorRef, Component, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthentService } from "./services/authent.service";
import { DeleteAccountService } from "./services/deleteAccount.service";
import { LoadingProvider } from "./services/loading/loading";
import { SettingsService } from "./services/settings.service";
import { environment } from "./env/env";
import { Events } from "./services/events/events";
import { DialogService } from "./services/dialog.service";
import { TokenService } from "./services/token.service";
import { storageKeys } from "./services/storage-keys";
import { Storage } from "@ionic/storage";
import { Globalization } from "@ionic-native/globalization/ngx";
import { Dialogs } from "@ionic-native/dialogs/ngx";
import { Router, NavigationStart } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { App } from "@capacitor/app";
import { ExternalLinkService } from "./services/external-link.service";
import { checkIsDesktop, checkLanguagePresent, getAppleStoreUrl, getPlayStoreUrl } from "./helperFunction";
import { Observable, Subscription } from "rxjs";
import { DataSyncService } from "./services/data-sync.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { ToastrService } from "ngx-toastr";
import { ConnectivityProvider } from "./services/connectivity/connectivity";
import { ToastOptions } from "@ionic-native/toast";
import { ToastProvider } from "./services/toast/toast";
import { CN, Colors } from "./constants";
import { SDSService } from "./services/sds.service";
import {
  AppRate,
  AppRateReviewTypeAndroid,
  AppRateReviewTypeIos,
} from "@awesome-cordova-plugins/app-rate";
import { MQuantStripModal } from "./components/mQuantStripModal/mQuantStripModal";
import { HistoryArticle } from "./types/types";
declare let window: any;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public isLogged = false;
  public isWeb = false;
  public playStoreBadge = "assets/play-badge-en.svg";
  public appleStoreBadge = "assets/apple-badge-en.svg";
  public cookieEnabled: Observable<boolean>;
  connectivitySubscription: Subscription;
  chinaWebPortalEnabled = environment.validityArea == CN;
  capPlatform = Capacitor.getPlatform();
  public showHead = true;
  public showFoot = true;
  //   public showFooter;
  public isDesktop: boolean;
  public screenWidth: any;
  public screenHeight: any;
  showMobFooter: boolean;
  private isHistoryMigrated = false;

  constructor(
    public platform: Platform,
    public statusBar: StatusBar,
    public translate: TranslateService,
    public authService: AuthentService,
    private router: Router,
    private navCtrl: NavController,
    private loading: LoadingProvider,
    private deviceService: DeviceDetectorService,
    private changeDetector: ChangeDetectorRef,
    private settings: SettingsService,
    private validityAreaLanguagesProvider: ValidityAreaLanguagesProvider,
    private favourites: FavouritesService,
    private dataService: DataSyncService,
    private histService: HistoryService,
    private storage: Storage,
    public connectivity: ConnectivityProvider,
    private toast: ToastProvider,
    private modalCtrl: ModalController,
    private dialogs: Dialogs,
    private deleteService: DeleteAccountService,
    public external: ExternalLinkService,
    private tokenService: TokenService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private globalization: Globalization,
    public events: Events,
    private zone: NgZone,
    public sdsService: SDSService
  ) {
    this.cookieEnabled = this.settings.cookieSetting;
    this.initializeApp();
    this.isDesktop = checkIsDesktop(this.deviceService);
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.showMobFooter = !this.isDesktop;
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        const urlsToHideBoth = [
          "/slides",
          "/moreOptions",
          "/login",
          "/passwordReset",
          "/signup",
          "/createaddlist",
        ];
        const url = event["url"];
        if (!this.isDesktop) {
          this.showHead = !urlsToHideBoth.includes(url);
          this.showFoot =
            !urlsToHideBoth.includes(url) && url !== "/userguide";
        } else {
          this.showHead = !urlsToHideBoth.includes(url);
        }
        if (url === "/userguide" && !this.isDesktop) {
          this.showFoot = true;
        }
      }
        
    });

    // this.sdsService.renderFooter.subscribe((val) => {
    //   this.showFooter = val;
    // });

    localStorage.setItem("mQuantVal", "0");
  }

  private initializeApp() {
    this.migrationMapsToAtlasProcess();
    this.platform.ready().then(async () => {
      this.connectivitySubscription = this.connectivity.isConnected.subscribe(
        (connected) => {
          options.duration = connected ? 3000 : 9400;
          options.message = this.translate.instant(
            connected ? "INTERNET_CONNECTED" : "INTERNET_DISCONNECTED"
          );
          options.styling.backgroundColor = connected ? "#29B8CD" : "#f53d3d";
          options.styling.opacity = connected ? 0.8 : 0.9;
          this.toast.showWithOptions(options);
        }
      );
      setTimeout(() => {
        this.loadFromStorage();
      }, 1000);

      if (this.capPlatform == "ios") {
        this.setiOSLanguage();
      }

      this.loading.loadChange.subscribe(() => {
        this.changeDetector.detectChanges();
      });

      this.setupDeepLinks();

      if (this.capPlatform != "web") {
        try {
          await SplashScreen.hide();
        } catch (e) {
          alert(e.toString());
        }
        if (this.capPlatform != "ios") {
          this.statusBar.styleDefault();
          this.statusBar.backgroundColorByHexString(Colors.darkslateblue);
          this.statusBar.overlaysWebView(false);
        }
        this.setupInitialNavigation();
      } else if (environment.validityArea == "CN") {
        this.initializeLanguageForChinese();
        this.storage.get(storageKeys.slideVisited).then((value) => {
          if (!value) {
            this.router.navigateByUrl("/slides", { replaceUrl: true });
          }
        });
      } else {
        this.isWeb = true;
        this.checkToken();
        const { material } =
          this.router.getCurrentNavigation().extractedUrl.queryParams;
        if (!material) {
          this.storage.get(storageKeys.slideVisited).then((value) => {
            if (!value) {
              this.router.navigateByUrl("/slides", { replaceUrl: true });
            }
          });
        } else {
          this.storage.set(storageKeys.slideVisited, true);
        }
      }

      this.authService.listenAuthentication().subscribe((value) => {
        this.isLogged = value;
        setTimeout(() => {
          this.favourites.loadFavourites();
          this.favourites.loadArchivedLists();
        }, 1000);
      });

      const options: ToastOptions = {
        position: "top",
        styling: {
          opacity: 0.95,
        },
      };
    });
  }

  async checkBrowserSupport() {
    const browserList = ["chrome", "safari", "edge", "firefox"];
    let isSupported = false;
    for (let browserName of browserList) {
      const detectedBrowser = this.deviceService.browser.toLowerCase();
      if (detectedBrowser.includes(browserName)) {
        if (browserName == "edge") {
          if (this.deviceService.browser_version < "79") {
            isSupported = false;
            break;
          } else {
            isSupported = true;
            break;
          }
        } else {
          isSupported = true;
          break;
        }
      } else {
        isSupported = false;
      }
    }

    if (!isSupported) {
      alert(this.translate.instant("BROWSER_NOT_SUPPORTED_TEXT"));
      alert(
        this.translate.instant("COOKIE_TEXT") +
          " " +
          this.translate.instant("PRIVACY_POLICY")
      );
    }
  }

  async checkToken() {
    this.tokenService.get().subscribe(async (value) => {
      if (!!value) {
        this.isLogged = !!value;
        try {
          await this.dataService.setServerSettingIfUserAlreadyLoggedIn();
          await this.authService.checkUserDataAndUserDeleted(value);
          this.initializeLanguage();
        } catch (error) {
          this.deleteService.clearLocalStorage();
          const resp = await this.dialogService.critical(
            "DELETED_ERROR",
            "ALREADY_DELETED"
          );
          if (resp) {
            this.router.navigateByUrl("/signup", { replaceUrl: true });
          }
        }
      } else {
        if (this.capPlatform != "ios") {
          this.initializeLanguage();
        }
      }
    });
  }

  async setupIntialRoute() {
    setTimeout(() => {
      this.openRatingDialog();
    }, 2000);
    const ret = await App.getLaunchUrl();
    if (!ret) {
      this.router.navigateByUrl("/landingpage", { replaceUrl: true });
      this.checkToken();
    }
  }

  async openMQuiantPopup() {
    const modal = await this.modalCtrl.create({
      component: MQuantStripModal,
      cssClass: "m-quant-strip-modal",
    });
    return await modal.present();
  }

  setupDeepLinks() {
    App.addListener("appUrlOpen", (data: any) => {
      this.zone.run(async () => {
        this.tokenService.get().subscribe(async (value) => {
          const urlArray = data.url.split(
            environment.region == "ROW" ? ".de/" : ".com/"
          );
          // await SplashScreen.hide();
          if (data.url.includes("favoriteLists")) {
            const slug = urlArray.pop();
            this.router.navigateByUrl(slug, { replaceUrl: true });
            const uuidURL = slug.split("=");
            if (uuidURL.length > 1) {
              const uuid = uuidURL.pop();
              if (!!value) {
                await this.favourites.importFavouriteList(uuid);
              } else {
                await this.dialogService.notify(
                  "IMPORT_FAVOURITE_IF_LOGGED_IN"
                );
              }
            }
          } else {
            this.router.navigateByUrl("/landingpage", { replaceUrl: true });
          }
          if (!!value) {
            this.isLogged = !!value;
            try {
              await this.dataService.setServerSettingIfUserAlreadyLoggedIn();
              await this.authService.checkUserDataAndUserDeleted(value);
            } catch (error) {
              this.deleteService.clearLocalStorage();
              await this.dialogService.critical(
                "DELETED_ERROR",
                "ALREADY_DELETED"
              );
            }
          } else {
            if (this.capPlatform != "ios") {
              this.initializeLanguage();
            }
          }
        });
      });
    });
  }

  async loadFromStorage() {
    try {
      await this.settings.loadSettings();
      if (!this.isHistoryMigrated) {
        await this.histService.loadHistory();
      }
      await this.validityAreaLanguagesProvider.loadValidityAreaLanguages();
    } catch (error) {}
  }

  public async logout() {
    await this.authService.logout();
    this.navCtrl.navigateRoot("/login");
  }

  async extractMobileCurrentLanguage() {
    try {
      const mobileLanguage = await this.globalization.getPreferredLanguage();
      const currentPhonelang = mobileLanguage.value.split("-")[0] || "";
      return Promise.resolve(currentPhonelang);
    } catch {
      return Promise.reject("");
    }
  }

  async setiOSLanguage() {
    const currentPhonelang = await this.extractMobileCurrentLanguage();
    const isLangPresent = checkLanguagePresent(currentPhonelang);
    this.translate.use(isLangPresent);
  }

  async initializeLanguageForChinese() {
    const appLanguage = await this.storage.get(storageKeys.fallbackLang);
    if (appLanguage) {
      this.translate.use(appLanguage);
      const settings = await this.storage.get(storageKeys.settings);
      if (settings) {
        settings.appLanguage = appLanguage;
        this.settings.updateGlobalLanguageSetting(settings);
      }
    } else {
      this.translate.use("zh");
    }
  }

  async initializeLanguage() {
    try {
      let isLangPresent:string;
      const appLanguage = await this.storage.get(storageKeys.fallbackLang);
      if (appLanguage) {
        this.translate.use(appLanguage);
        isLangPresent = appLanguage;
      } else {
        if (this.capPlatform == "android") {
          const currentPhonelang = await this.extractMobileCurrentLanguage();
          isLangPresent = checkLanguagePresent(currentPhonelang);
        } else {
          const language = this.translate.getBrowserCultureLang();
          isLangPresent = checkLanguagePresent(language);
        }
      }
      const settings = await this.storage.get(storageKeys.settings);
      isLangPresent = settings.appLanguage || isLangPresent;
      if (isLangPresent) {
        this.playStoreBadge = `assets/play-badge-${isLangPresent}.svg`;
        this.appleStoreBadge = `assets/apple-badge-${isLangPresent}.svg`;
        if (settings) {
          settings.appLanguage = isLangPresent;
          this.settings.updateGlobalLanguageSetting(settings);
        }
        this.translate.use(isLangPresent);
      } else {
        this.translate.use("en");
      }

      if (this.capPlatform == "web") {
        this.checkBrowserSupport();
        this.checkNetworkConnectivity();
      }
    } catch (error) {
      this.translate.use("en");
    }
  }

  // future refernce
  public navigateToAppleStore() {
    let country = environment.validityArea.toLowerCase();
    let settingUnsubscribe = this.settings.settings.subscribe((settingInfo) => {
      country = settingInfo.validityAreaLanguage.validityArea.toLowerCase();
    });
    settingUnsubscribe.unsubscribe();    
    this.external.openExternalUrl(getAppleStoreUrl(country));
  }

  public navigateToPlayStore() {
    this.external.openExternalUrl(getPlayStoreUrl());    
  }

  private setupInitialNavigation() {
    this.storage.get(storageKeys.slideVisited).then(async (slideVisited) => {
      if (slideVisited) {
        this.setupIntialRoute();
      } else {
        if (this.capPlatform != "ios") {
          this.initializeLanguage();
        }
        // await SplashScreen.hide();
        this.router.navigateByUrl("/slides", { replaceUrl: true });
      }
    });
  }

  public navigateLegalPage(): void {
    this.router.navigateByUrl("/impressum", { replaceUrl: true });
    this.cookieAccepted();
  }

  public cookieAccepted() {
    this.storage.set(storageKeys.cookieAccepted, true);
    this.settings.hideCookieNotification();
  }

  private disconnectedMsg() {
    const msg = this.translate.instant("INTERNET_DISCONNECTED");
    this.toastr.error(msg, "", {
      timeOut: 3000,
    });
  }

  private checkNetworkConnectivity() {
    const networkState = navigator.onLine;
    if (!networkState) {
      this.disconnectedMsg();
    }
    window.addEventListener("online", () => {
      const msg = this.translate.instant("INTERNET_CONNECTED");
      this.toastr.success(msg, "", {
        timeOut: 2000,
      });
    });
    window.addEventListener("offline", () => {
      this.disconnectedMsg();
    });
  }

  openRatingDialog() {
    AppRate.setPreferences({
      simpleMode: true,
      displayAppName: "My M Safety App",
      usesUntilPrompt: 6,
      promptAgainForEachNewVersion: false,
      reviewType: {
        ios: AppRateReviewTypeIos.AppStoreReview,
        android: AppRateReviewTypeAndroid.InAppBrowser,
      },
      storeAppURL: {
        ios: environment.storeAppURL.ios,
        android: environment.storeAppURL.android,
      },
      customLocale: {
        //not all values are applied because were using simple mode
        title: this.translate.instant("RATING_TITLE"),
        message: this.translate.instant("RATING_MESSAGE"),
        cancelButtonLabel: this.translate.instant("RATING_CANCEL_BUTTON_LABEL"),
        laterButtonLabel: this.translate.instant("RATING_LATER_BUTTON_LABEL"),
        rateButtonLabel: this.translate.instant("RATING_RATE_BUTTON_LABEL"),
        yesButtonLabel: this.translate.instant("RATING_YES_BUTTON_LABEL"),
        noButtonLabel: this.translate.instant("RATING_NO_BUTTON_LABEL"),
        appRatePromptTitle: this.translate.instant(
          "RATING_APP_RATE_PROMPT_TITLE"
        ),
        feedbackPromptTitle: this.translate.instant(
          "RATING_FEEDBACK_PROMPT_TITLE"
        ),
      },
      callbacks: {
        onButtonClicked: (buttonIndex) => {
          if (buttonIndex === 3) {
            this.dialogs
              .confirm(
                "",
                this.translate.instant("RATING_FEEDBACK_PROMPT_TITLE"),
                [
                  this.translate.instant("RATING_REPORT"),
                  this.translate.instant("RATING_NO"),
                ]
              )
              .then((index) => {
                if (index === 1) {
                  this.router.navigate(["/contact"]);
                }
              });
          }
        },
      },
    });

    AppRate.promptForRating(false);
  }

  ionViewWillUnload(): void {
    this.connectivitySubscription &&
      this.connectivitySubscription.unsubscribe();
  }

  /**
   * Move old favourites to archived
   * add isMaps flags to history for disable
   */
  private async migrationMapsToAtlasProcess() {
    let oldFavoritesList =
      (await this.storage.get(storageKeys.favoritesLists)) || [];
    let historyList = (await this.storage.get(storageKeys.history)) || [];
    this.storage.get(storageKeys.isarchivedDone).then(async (value) => {
      if (!value) {
        if (oldFavoritesList?.length) {
          await this.storage.set(storageKeys.favoritesLists, []);
          await this.storage.set(
            storageKeys.archivedLists,
            JSON.parse(JSON.stringify(oldFavoritesList))
          );
        }
        if (historyList?.length) {
          let oldHistory = historyList.map((item: HistoryArticle) => {
            return {
              isMaps: true,
              ...item,
            };
          });
          this.isHistoryMigrated = true;
          await this.histService.setHistoryData(
            JSON.parse(JSON.stringify(oldHistory))
          );
        }
        await this.storage.set(storageKeys.isarchivedDone, true);
      }
    });
  }
}
