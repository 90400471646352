import { BehaviorSubject, Observable } from "rxjs";
import { storageKeys } from "./../storage-keys";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ValidityAreaLanguages } from "../../types/types";
import { valditiyAreaLanguageDefault } from "./validity-area-language.mock";
import { environment } from "../../env/env";

@Injectable({
  providedIn: "root",
})
export class ValidityAreaLanguagesProvider {
  private validityAreaLanguages$: BehaviorSubject<ValidityAreaLanguages[]> =
    new BehaviorSubject(valditiyAreaLanguageDefault);

  validityAreaLanguages = this.validityAreaLanguages$.asObservable();

  constructor(public http: HttpClient, private storage: Storage) {}

  async loadValidityAreaLanguages(): Promise<any> {
    await this.loadValidityAreaLanguageFromStorage();
    await this.loadValidityAreaLanguageFromApi();
  }

  private async loadValidityAreaLanguageFromStorage() {
    try {
      const currentValidityAreaLanguages = await this.storage.get(
        storageKeys.validityAreaLanguages
      );
      this.validityAreaLanguages$.next(
        currentValidityAreaLanguages
          ? currentValidityAreaLanguages
          : valditiyAreaLanguageDefault
      );
    } catch (error) {
      console.log("loadValidityAreaLanguageFromStorage error", error);
    }
  }

  private async loadValidityAreaLanguageFromApi() {
    let newValidityAreaLanguages =
      await this.getAllValidityAreaLanguages().toPromise();
    //newValidityAreaLanguages = newValidityAreaLanguages.filter(item => item.validityArea != 'EU'); //Revised according to the MMS-2020 story
    if (newValidityAreaLanguages) {
      this.validityAreaLanguages$.next(newValidityAreaLanguages);
      await this.saveValidityAreaLanguage(newValidityAreaLanguages);
    }
  }

  private getAllValidityAreaLanguages(): Observable<ValidityAreaLanguages[]> {
    return this.http.get<ValidityAreaLanguages[]>(
      `${environment.baseUrl}/${environment.apiVersion}/safetydata/configurations`
    );
  }

  findValidityAreaLanguagesFor(validityArea: string): ValidityAreaLanguages {
    return this.validityAreaLanguages$
      .getValue()
      .find(
        (validityAreaLanguages) =>
          validityAreaLanguages.validityArea === validityArea
      );
  }

  private saveValidityAreaLanguage(
    validityAreaLanguages: ValidityAreaLanguages[]
  ) {
    return this.storage.set(
      storageKeys.validityAreaLanguages,
      validityAreaLanguages
    );
  }
}
