import { Subject } from "rxjs";
import { Network as N1 } from "@ionic-native/network/ngx";
import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";

@Injectable({
  providedIn: "root",
})
export class ConnectivityProvider {
  isConnected: Subject<boolean> = new Subject();
  currentStatus = "online";
  connectedStatus = true;

  constructor(public network: N1) {
    this.checkNetworkStatus();
    network.onConnect().subscribe((data) => {
      if (this.currentStatus != data.type) {
        this.isConnected.next(true);
      }
      this.currentStatus = data.type;
      this.connectedStatus = true;
    });
    network.onDisconnect().subscribe((data) => {
      if (this.currentStatus != data.type) {
        this.isConnected.next(false);
      }
      this.currentStatus = data.type;
      this.connectedStatus = false;
    });
  }

  async checkNetworkStatus() {
    try {
      const { connected } = await Network.getStatus();
      this.connectedStatus = connected;
    } catch (error) {
      this.connectedStatus = false;
    }
  }

  public checkNetworkConnectivity(): boolean {
    if (Capacitor.getPlatform() == "web") {
      return navigator.onLine;
    } else {
      return this.connectedStatus;
    }
  }
}
