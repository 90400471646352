import { Injectable } from "@angular/core";
import { Toast, ToastOptions } from "@ionic-native/toast/ngx/";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ToastProvider {
  constructor(private platform: Platform, private toast: Toast) {}

  showWithOptions(options: ToastOptions) {
    if (this.platform.is("android") || this.platform.is("ios")) {
      this.toast.showWithOptions(options).subscribe();
    } else {
      console.log("ToastProvider", options.message);
    }
  }

  hide() {
    if (this.platform.is("android") || this.platform.is("ios")) {
      this.toast.hide();
    }
  }
}
