import { storageKeys } from "./storage-keys";
import { Injectable } from "@angular/core";
import { DialogService } from "./dialog.service";
import { LocationService } from "./location.service";
import { Storage } from "@ionic/storage";
import { Settings, ISettings, ValidityAreaLanguages } from "../types/types";
import { BehaviorSubject } from "rxjs";
import { ValidityAreaLanguagesProvider } from "./validity-area-languages/validity-area-languages";
import { Location } from "../types/types";
import { SafetyDataAPI } from "./safety-data-api.service";
import { Capacitor } from "@capacitor/core";
import { TokenService } from "./token.service";
import { environment } from "../env/env";
import { CN } from "../constants";
import { ModalController } from "@ionic/angular";
import { UpdateLocationPopup } from "../components/UpdateLocationPopup/UpdateLocationPopup";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private settings$: BehaviorSubject<ISettings> =
    new BehaviorSubject<ISettings>(null);

  private cookieSetting$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  cookieSetting = this.cookieSetting$.asObservable();
  chinaWebPortalEnabled = environment.validityArea == CN;

  settings = this.settings$.asObservable();
  // currentMobileLanguage: any;
  private token: string;

  constructor(
    public dialogService: DialogService,
    public locationService: LocationService,
    private storage: Storage,
    private tokenService: TokenService,
    private validityAreaLanguagesProvider: ValidityAreaLanguagesProvider,
    public modalCtrl: ModalController,
    private safetyDataAPI: SafetyDataAPI
  ) {
    this.tokenService.getContinuous().subscribe((token) => {
      this.token = token;
    });
  }

  async loadSettings() {
    let settings = await this.loadSettingsFromStorage();
    if (!settings) {
      settings = this.getDefaultSettings();
      this.saveSettings(settings);
    } else {
      let { language, validityArea } = settings.validityAreaLanguage;
      let isChange = false;
      if (language) {
        let _lang;
        if (language == "Z8") {
          _lang = "EN";
          isChange = true;
        } else if (language == "Z9") {
          _lang = "PT";
          isChange = true;
        } else if (language == "3F") {
          _lang = "FR";
          isChange = true;
        }
        if (_lang) {
          settings.validityAreaLanguage.language = _lang;
        }
      }
      if (validityArea) {
        const notAvailableCountry = [
          "AL",
          "AR",
          "CO",
          "CR",
          "EG",
          "IN",
          "MX",
          "PE",
          "PH",
        ];
        if (notAvailableCountry.find((nc) => nc == validityArea)) {
          validityArea = environment.validityArea;
          settings.validityAreaLanguage.validityArea = validityArea;
          isChange = true;
        }
      }
      if (isChange) {
        await this.storage.set(storageKeys.settings, settings);
      }
    }
    this.settings$.next(settings);
  }

  get validityArea() {
    try {
      return this.settings$.getValue().validityAreaLanguage.validityArea;
    } catch (e) {
      console.log("Validity Area error ", e);
    }
  }

  get language() {
    try {
      let lang = this.settings$.getValue().validityAreaLanguage.language;
      if (lang == "Z8") {
        return "EN";
      } else if (lang == "Z9") {
        return "PT";
      } else if (lang == "3F") {
        return "FR";
      }
      return lang;
    } catch (e) {
      console.log("language error ", e);
    }
  }

  async updateValidityAreaAndLanguage(validityArea: string, language: string) {
    const currentSettings = this.settings$.getValue();
    currentSettings.validityAreaLanguage.validityArea = validityArea;
    currentSettings.validityAreaLanguage.language = language;
    const isLangPresent = this.syncToMobileLang(language);
    if (
      !this.chinaWebPortalEnabled &&
      isLangPresent &&
      Capacitor.getPlatform() != "ios"
    ) {
      currentSettings.appLanguage = isLangPresent.toLowerCase();
      this.storage.set(storageKeys.fallbackLang, isLangPresent.toLowerCase());
      if (Capacitor.getPlatform() == "web") {
        this.dialogService.appRestartToast("LANGUAGE_CHANGE_FOR_WEB");
      } else {
        this.dialogService.appRestartToast("LANGUAGE_CHANGE");
      }
    }
    await this.updateSettings(currentSettings);
  }

  async updateValidityAreaAfterClearLocalData(
    validityArea: string,
    language: string
  ) {
    const currentSettings = this.settings$.getValue();
    currentSettings.validityAreaLanguage.validityArea = validityArea;
    currentSettings.validityAreaLanguage.language = language;
    await this.updateSettings(currentSettings);
  }

  async updateValidityAreaLanguage(
    valditiyAreaLanguages: ValidityAreaLanguages
  ) {
    await this.updateValidityAreaAndLanguage(
      valditiyAreaLanguages.validityArea,
      valditiyAreaLanguages.languages[0]
    );
  }

  async updateSettings(settings: ISettings) {
    this.settings$.next(settings);
    await this.saveSettings(settings);
  }

  async updateGlobalLanguageSetting(settings: ISettings) {
    this.settings$.next(settings);
    this.storage.set(storageKeys.settings, settings);
  }

  async resetLocationError() {
    try {
      const currentSettings = this.settings$.getValue();
      currentSettings.location.error = 0;
      await this.updateSettings(currentSettings);
    } catch (e) {
      console.log("resetLocationError ", e);
    }
  }

  public loadSettingsFromStorage(): Promise<ISettings> {
    return this.storage.get(storageKeys.settings);
  }

  private saveSettings(settings: ISettings) {
    this.storage.set(storageKeys.settings, settings);
    return this.safetyDataAPI.saveSettings(settings);
  }

  async updateLocationSettings() {
    if (Capacitor.getPlatform() != "web") {
      this.compareValidityAreaWithLocation();
    } else {
      this.fetchCountryAndCompareValidity();
    }
  }

  private async fetchCountryAndCompareValidity() {
    try {
      if (!this.settings$.getValue()) await this.loadSettings();
      const currentSettings = this.settings$.getValue();
      const { country = environment.validityArea } =
        await this.locationService.getCountry();
      if (country != currentSettings.validityAreaLanguage.validityArea) {
        if (currentSettings.location.askLocationAgain) {
          const modal = await this.modalCtrl.create({
            component: UpdateLocationPopup,
            cssClass: "update-location-popup",
            componentProps: {
              onDone: async (response: { button: boolean; input: boolean }) => {
                currentSettings.location.askLocationAgain = !response.input;
                if (!currentSettings.location.askLocationAgain) {
                  currentSettings.location.locationUpdate = response.button;
                }
                this.updateSettings(currentSettings);
                if (response.button) {
                  const validityAreaLanguage =
                    this.validityAreaLanguagesProvider.findValidityAreaLanguagesFor(
                      country
                    );
                  await this.updateValidityAreaLanguage(validityAreaLanguage);
                }
              },
            },
          });
          await modal.present();
        } else {
          if (currentSettings.location.locationUpdate) {
            // Update current location automatically
            const validityAreaLanguage =
              this.validityAreaLanguagesProvider.findValidityAreaLanguagesFor(
                country
              );
            await this.updateValidityAreaLanguage(validityAreaLanguage);
          }
        }
      }
    } catch (e) {
      console.log("Error fetchCountry", e);
    }
  }

  private async compareValidityAreaWithLocation() {
    try {
      if (!this.settings$.getValue()) await this.loadSettings();
      const currentSettings = this.settings$.getValue();
      let currentLocation = null;
      currentLocation = await this.locationService.getCurrentLocation();
      let lastTimeCountryOrTimeModified = await this.storage.get(
        storageKeys.lastTimeCountryOrLanguageModified
      );
      lastTimeCountryOrTimeModified = lastTimeCountryOrTimeModified || 0;
      const differenceToNow = Date.now() - lastTimeCountryOrTimeModified;
      const oneDay = 1000 * 60 * 60 * 24; // 1000 ms, 60 s, 60 m, 24 h.

      if (currentLocation && differenceToNow > oneDay) {
        // Success: Current location determined
        if (
          currentLocation.countryCode !=
          currentSettings.validityAreaLanguage.validityArea
        ) {
          if (currentSettings.location.askLocationAgain) {
            const modal = await this.modalCtrl.create({
              component: UpdateLocationPopup,
              cssClass: "update-location-popup",
              componentProps: {
                onDone: async (response: {
                  button: boolean;
                  input: boolean;
                }) => {
                  currentSettings.location.askLocationAgain = !response.input;
                  if (!currentSettings.location.askLocationAgain) {
                    currentSettings.location.locationUpdate = response.button;
                  }
                  this.updateSettings(currentSettings);
                  if (response.button) {
                    const validityAreaLanguage =
                      this.validityAreaLanguagesProvider.findValidityAreaLanguagesFor(
                        currentLocation.countryCode
                      );
                    await this.updateLocation(currentSettings, currentLocation);
                    await this.updateValidityAreaLanguage(validityAreaLanguage);
                  }
                },
              },
            });
            await modal.present();
          } else {
            if (currentSettings.location.locationUpdate) {
              // Update current location automatically
              const validityAreaLanguage =
                this.validityAreaLanguagesProvider.findValidityAreaLanguagesFor(
                  currentLocation.countryCode
                );
              await this.updateLocation(currentSettings, currentLocation);
              await this.updateValidityAreaLanguage(validityAreaLanguage);
            }
          }
        }
      } else {
        console.log("no location");
      }
    } catch (error) {
      console.log("location error", error);
    }
  }

  private async updateLocation(
    currentSettings: ISettings,
    currentLocation: Location
  ) {
    currentSettings.location.city = currentLocation.city;
    currentSettings.location.countryCode = currentLocation.countryCode;
    currentSettings.location.lat = currentLocation.lat;
    currentSettings.location.long = currentLocation.long;
    currentSettings.location.error = currentLocation.error;
    await this.updateSettings(currentSettings);
  }

  async clearStorage() {
    await this.storage.remove(storageKeys.settings);
    await this.storage.remove(storageKeys.history);
    await this.storage.remove(storageKeys.favoritesLists);
    await this.storage.remove(storageKeys.gridFrequencies);
    await this.storage.remove(storageKeys.showSafetyTagHint);
    await this.loadSettings();
  }

  private getDefaultSettings(): ISettings {
    return new Settings();
  }

  public showCookieNotification() {
    this.cookieSetting$.next(true);
  }

  public hideCookieNotification() {
    this.cookieSetting$.next(false);
  }

  public syncToMobileLang(selectedLanguage: string): any {
    const availableLang = [
      "DE",
      "EN",
      "Z8",
      "ES",
      "FR",
      "ZH",
      "ZF",
      "JA",
      "IT",
      "PT",
      "3F",
    ];
    let isLangSet = "";
    for (let item of availableLang) {
      if (item == selectedLanguage) {
        if (item == "Z8") item = "EN";
        if (item == "ZF") item = "ZH";
        if (item == "3F") item = "FR";
        isLangSet = item.toLowerCase();
        break;
      }
    }
    return isLangSet;
  }
  public setWechatApis(url: string) {
    return this.safetyDataAPI.initWechatApis(url);
  }
}
