import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ExternalLinkService } from "../../services/external-link.service";

@Component({
  selector: "mQuantStripModal",
  templateUrl: "./mQuantStripModal.html",
  styleUrls: ["./mQuantStripModal.scss"],
})
export class MQuantStripModal {
  constructor(
    private modalCtrl: ModalController,
    public external: ExternalLinkService,
  ) {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  public navigateToPlayStore() {
    this.external.openExternalUrl(
      "https://play.google.com/store/apps/details?id=de.merck.mquant.stripscan.googleplay"
    );
  }

  public navigateToAppleStore() {
    this.external.openExternalUrl("https://apps.apple.com/us/app/id1362147895");
  }
}
