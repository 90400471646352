import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import { Settings, FavoritesList, ISettings } from "../types/types";
import { storageKeys } from "./storage-keys";
import { Storage } from "@ionic/storage";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import { SafetyDataAPI } from "./safety-data-api.service";
import { DialogService } from "./dialog.service";
import { Events } from "./events/events";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class DataSyncService {
  private localFavorites: FavoritesList[];
  private localSettings: ISettings;

  private serverSettings: ISettings;

  private hasFavoritesForSync: boolean = false;
  private usingDefaultSettings: boolean = false;
  private hasArchiveForSync: boolean = false;
  private localArchivedList: FavoritesList[];

  constructor(
    private settingsService: SettingsService,
    private events: Events,
    private storage: Storage,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private safetyAPIService: SafetyDataAPI,
    private dialogService: DialogService
  ) {}

  async checkForDataChanges() {
    await this.getLocalData();
    await this.getServerData();
    this.hasFavoritesForSync = this.hasFavoritesInStorage();
    this.usingDefaultSettings = this.areDefaultSettings();
    this.hasArchiveForSync = this.hasAcrhiveInStorage();

    if (
      this.usingDefaultSettings &&
      !this.hasFavoritesForSync &&
      !this.hasArchiveForSync
    ) {
      if (!this.serverSettings) {
        this.updateSettings(this.localSettings);
      } else {
        this.updateSettings(this.serverSettings);
      }
    } else {
      this.showSyncOptions();
    }
  }

  private async updateSettings(settings) {
    await this.settingsService.updateSettings(settings);
  }

  private evaluateSettingsActions(checked: boolean) {
    if (checked) {
      this.updateSettings(this.localSettings);
    } else {
      if (this.serverSettings) {
        this.updateSettings(this.serverSettings);
      } else {
        this.updateSettings(new Settings());
      }
    }
  }

  private async evaluateFavoritesActions(checked: boolean) {
    if (checked) {
      try {
        this.localFavorites = this.localFavorites.map((favList) => {
          favList.articles = favList.articles.map((favItem) => {
            return { ...favItem, offlineAvailable: false };
          });
          return { ...favList, offlineAvailable: false };
        });
        await this.safetyAPIService.createMultiplesFavoritesList(
          this.localFavorites
        );
        this.storage.remove(storageKeys.favoritesLists);
        this.events.publish("favorites:load");
      } catch {
        this.dialogService.notify("UNEXPECTED_ERROR");
      }
    }
  }

  public async showSyncOptions() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("DATA_SYNC_ALERT_TITLE"),
      message: this.translate.instant("DATA_SYNC_ALERT_MESSAGE"),
      inputs: this.getCheckboxes(),
      cssClass: "data-sync-popup",
      buttons: [
        {
          text: this.translate.instant("COMMON.OK"),
          handler: (selectedItems) => {
            this.evaluateSettingsActions(
              selectedItems.indexOf("settings") != -1
            );
            this.evaluateFavoritesActions(
              selectedItems.indexOf("favorites") != -1
            );
            this.syncArchivedData(selectedItems.indexOf("archived") != -1);
          },
        },
      ],
    });
    await alert.present();
  }

  private async syncArchivedData(checked: boolean) {
    if (checked) {
      try {
        await this.safetyAPIService.createMultiplesArchivedList(
          this.localArchivedList
        );
        this.storage.remove(storageKeys.archivedLists);
        this.events.publish("archives:load");
      } catch {
        this.dialogService.notify("UNEXPECTED_ERROR");
      }
    }
  }

  private getCheckboxes() {
    const inputs = [];
    if (this.hasFavoritesForSync) {
      inputs.push({
        type: "checkbox",
        label: this.translate.instant("DATA_SYNC_ALERT_FAVORITES_LABEL"),
        value: "favorites",
        checked: true,
      });
    }
    if (!this.usingDefaultSettings) {
      inputs.push({
        type: "checkbox",
        label: this.translate.instant("DATA_SYNC_ALERT_SETTINGS_LABEL"),
        value: "settings",
        checked: true,
      });
    }
    if (this.hasArchiveForSync) {
      inputs.push({
        type: "checkbox",
        label: this.translate.instant("DATA_SYNC_ALERT_ARCHIVES_LABEL"),
        value: "archived",
        checked: true,
      });
    }

    return inputs;
  }

  async getLocalData() {
    this.localSettings =
      (await this.settingsService.loadSettingsFromStorage()) || new Settings();
    this.localFavorites =
      (await this.storage.get(storageKeys.favoritesLists)) || [];
    this.localArchivedList =
      (await this.storage.get(storageKeys.archivedLists)) || [];
  }

  async getServerData() {
    try {
      this.serverSettings = await this.safetyAPIService.getSettings();
      const { appLanguage = "" } = this.serverSettings;
      if (appLanguage) {
        this.settingsService.updateGlobalLanguageSetting(this.serverSettings);
        this.translate.use(appLanguage);
      }
    } catch {
      this.serverSettings = null;
    }
  }

  private hasFavoritesInStorage(): boolean {
    if (
      this.localFavorites.length == 1 &&
      !this.localFavorites[0].articles.length
    ) {
      this.localFavorites = [];
    }
    return !!this.localFavorites.length;
  }

  private hasAcrhiveInStorage(): boolean {
    return this.localArchivedList?.length > 0;
  }

  private areDefaultSettings(): boolean {
    return JSON.stringify(this.localSettings) == JSON.stringify(new Settings());
  }

  public async setServerSettingIfUserAlreadyLoggedIn() {
    try {
      await this.getServerData();
      if (this.serverSettings) {
        this.updateSettings(this.serverSettings);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  }
}
