import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Article, FavoritesList } from "../../types/types";
import { ModalController } from "@ionic/angular";
import { AddListAlertPopup } from "../AddListAlertPopup/AddListAlertPopup";
@Component({
  selector: "AddListModelPopup",
  templateUrl: "AddListModelPopup.html",
  styleUrls: [],
})
export class AddListModelPopup {
  @Input() favoriteList: FavoritesList[];

  @Input() article: Article;

  @Input() onDone: (names: string[]) => Promise<void>;

  checkedItems: string[] = [];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.checkedItems = [];
    const listName = localStorage.getItem("listName");
    this.favoriteList.forEach((fav) => {
      if (
        fav.articles.some(
          (art) => art.materialNumber === this.article.materialNumber
        ) ||
        fav.name === listName
      ) {
        this.checkedItems.push(fav.name);
      }
    });
    localStorage.removeItem("listName");
  }

  public async addListPopup() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: AddListAlertPopup,
      cssClass: "add-list-alert-popup",
      componentProps: {
        article: this.article,
      },
    });
    return await modal.present();
  }

  closeWin() {
    this.modalCtrl.dismiss();
  }

  isChecked(fav: FavoritesList) {
    return this.checkedItems.includes(fav.name);
  }

  onChange(item: FavoritesList) {
    if (this.checkedItems.includes(item.name)) {
      this.checkedItems = this.checkedItems.filter((name) => item.name != name);
    } else {
      this.checkedItems.push(item.name);
    }
  }

  public async onSaveClick() {
    await this.onDone(this.checkedItems);
    this.closeWin();
  }
}
