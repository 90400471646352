export const CN = "CN";
export const OLD = "old";

export const DisableTimer = 200;

export const Colors = {
  primary: "#488aff",
  secondary: "#32db64",
  danger: "#f53d3d",
  light: "#f4f4f4",
  dark: "#222",
  eggblue: "#96D7D2",
  strongblue: "#29B8CD",
  darkslateblue: "#503291",
  rose: "#EB3C96",
  persianblue: "#005CA9",
  sunglow: "#FFC832",
  amaranth: "#E61E50",
  android: "#A5CD50",
  iceberg: "#e5f5f4",
  surfiegreen: "#3c8b84",
  isabelline: "rgba(237, 237, 237, 0.9)",
  ashgrey: "rgba(178, 178, 178, 1)",
  linkedin: "#0077b5",
  transparent: "transparent",
};

export const DynamicTemplateClass = {
  big: "big-tags",
  old: "old-tags",
  big_2: "big-tags",
  medium: "medium-tags",
  milliseq: "milliseq-tags",
  doubleMilliseq: "big-tags",
  small: "small-tags",
  multiple: "small-tags",
  tag_rotated: "tag_rotated",
  old_tag_rotated: "old_tag_rotated",
  rotated_0: "rotate-by_0",
};

export const Degrees = {
  zero: 0,
  minus_90: -90,
  minus_270: -270,
  minus_180: -180,
};

export const Anti_clockwise_rotation = [-90, -180, -270, 0];

export const BRAND_NAMES = new Map([
  ["AL", "ALDRICH"],
  ["AV", "AVANTI"],
  ["CE", "CERILLIAN"],
  ["DV", "FLUKA"],
  ["FL", "FLUKA"],
  ["GN", "GENOSYS"],
  ["HW", "HONEYWELL"],
  ["KT", "SAJ"],
  ["MI", "MM"],
  ["NB", "SUPELCO"],
  ["RD", "RIEDEL"],
  ["RO", "ROCHE"],
  ["SA", "SIAL"],
  ["SG", "SIGALD"],
  ["SI", "SIGMA"],
  ["SU", "SUPELCO"],
  ["US", "USP"],
  ["VT", "VETEC"],
  ["BJ", "BANDJ"],
  ["BR", "SIAL"],
]);
