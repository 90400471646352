import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { Observable, BehaviorSubject, of } from "rxjs";
import {
  map,
  filter,
  mergeMap,
  flatMap,
  catchError,
  tap,
} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  token: string;
  private tokenSubject$: BehaviorSubject<string>;

  constructor(private storageService: StorageService) {
    this.tokenSubject$ = new BehaviorSubject(null);
  }

  public get(): Observable<string> {
    if (!this.token) {
      return this.storageService.get("token").pipe(
        tap((token) => {
          this.token = token;
          this.updateTokenSubject();
        })
      );
    } else {
      return of(this.token);
    }
  }

  /**
   * Returns a subject which will deliver the state
   * of the token anytime it is changed.
   */
  public getContinuous(): Observable<string> {
    return this.tokenSubject$.asObservable();
  }

  public set(token: string): void {
    console.log("The token has been set");
    this.storageService.set("token", token);
    this.token = token;
    this.updateTokenSubject();
  }

  public remove(): void {
    this.storageService.remove("token");
    this.token = undefined;
    this.updateTokenSubject();
  }

  private updateTokenSubject(): void {
    this.tokenSubject$.next(this.token);
  }
}
